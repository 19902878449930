<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Vendors" title="Create Vendor" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="tab2-card">
              <div class="card-body">
                <b-tabs content-class="mt-3">
                  <div class="tab-content" id="myTabContent">
                    <b-tab title="Vendor" data-feather="user" active>
                      <ValidationObserver ref="form">
                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                          aria-labelledby="account-tab">
                          <form>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> First
                                  Name</label>
                                <div class="col-xl-8 col-md-7">
                                  <input class="form-control" id="validationCustom0" type="text" v-model="firstName"
                                    name="First Name" />
                                  <span class="text-danger">
                                    {{
                                      errors[0]
                                    }}
                                  </span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> Last
                                  Name</label>
                                <div class="col-xl-8 col-md-7">
                                  <input class="form-control" id="validationCustom0" type="text" v-model="lastName"
                                    name="Last Name" />
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required|email" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> Email</label>
                                <div class="col-xl-8 col-md-7">
                                  <input class="form-control" id="validationCustom0" type="email" v-model="email"
                                    name="Email" />
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> Vendor
                                  Name</label>
                                <div class="col-xl-8 col-md-7">
                                  <input class="form-control" id="validationCustom0" type="text" v-model="name"
                                    name="Vendor Name" />
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <!-- add logo fields -->
                            <ValidationProvider>
                              <div class="row form-group">
                                <div class="col-xl-3 col-md-4">
                                  <label for="logo1">
                                    <span class="text-danger">*</span>
                                    Logo
                                  </label>
                                </div>
                                <div class="col-xl-8 col-md-7">
                                  <input type="file" class="form-control" name="logo" id="logo1"
                                    @change="onFileSelected">
                                </div>
                                <!-- <span class="text-danger">{{ errors[0] }}</span> -->
                              </div>
                            </ValidationProvider>
                            <!-- add logo fields -->
                            <ValidationProvider>
                              <div class="row form-group">
                                <div class="col-xl-3 col-md-4">
                                  <label for="bg1">
                                    <span class="text-danger">*</span>
                                    Background
                                  </label>
                                </div>
                                <div class="col-xl-8 col-md-7">
                                  <input type="file" class="form-control" name="background" id="bg1"
                                    @change="onBgSelected">
                                </div>
                                <!-- <span class="text-danger">{{ errors[0] }}</span> -->
                              </div>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> Address</label>
                                <div class="col-xl-8 col-md-7">
                                  <input class="form-control" id="validationCustom0" type="text" v-model="address"
                                    name="Address" />
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> Phone
                                  Number</label>
                                <div class="col-xl-8 col-md-7">
                                  <input class="form-control" id="validationCustom0" type="text" pattern="[0-9]{4,11}"
                                    v-model="phone_number" name="Phone Number" />
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span
                                    class="text-danger">*</span> Level</label>
                                <div class="col-xl-8 col-md-7">
                                  <select class="custom-select" required="" v-model="level">
                                    <option value="">--Select--</option>
                                    <option v-for="option in levelsData" :key="option.id" :value="option.id">
                                      {{ option.value }}
                                    </option>
                                  </select>
                                  <span class="text-danger">{{
                                    errors[0]
                                  }}</span>
                                </div>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider>
                              <div class="row form-group">
                                <label for="bio1" class="col-xl-3 col-md-4"> Bio
                                </label>
                                <div class="col-xl-8 col-md-7">
                                  <textarea name="bio" v-model="bio" id="bio1"
                                    class="form-control"></textarea>
                                </div>
                              </div>
                            </ValidationProvider>
                          </form>
                        </div>
                      </ValidationObserver>
                    </b-tab>
                    <!-- <b-tab data-feather="lock" title="Permission">
                      <form class="needs-validation user-add">
                        <h4>Permission</h4>
                        <div class="permission-block">
                          <div class="attribute-blocks">
                            <h5 class="f-w-600 mb-3">
                              Product Related Permission
                            </h5>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Add Product</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani1">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani1"
                                      type="radio"
                                      name="rdo-ani"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani2">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani2"
                                      type="radio"
                                      name="rdo-ani"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Update Product</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani3">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani3"
                                      type="radio"
                                      name="rdo-ani1"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani4">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani4"
                                      type="radio"
                                      name="rdo-ani1"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Delete Product</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani5">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani5"
                                      type="radio"
                                      name="rdo-ani2"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani6">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani6"
                                      type="radio"
                                      name="rdo-ani2"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label class="mb-0 sm-label-radio"
                                  >Apply Discount</label
                                >
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0"
                                >
                                  <label class="d-block mb-0" for="edo-ani7">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani7"
                                      type="radio"
                                      name="rdo-ani3"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block mb-0" for="edo-ani8">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani8"
                                      type="radio"
                                      name="rdo-ani3"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="attribute-blocks">
                            <h5 class="f-w-600 mb-3">
                              Category Related Permission
                            </h5>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Add Category</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani9">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani9"
                                      type="radio"
                                      name="rdo-ani4"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani10">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani10"
                                      type="radio"
                                      name="rdo-ani4"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Update Category</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani11">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani11"
                                      type="radio"
                                      name="rdo-ani5"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani12">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani12"
                                      type="radio"
                                      name="rdo-ani5"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label>Delete Category</label>
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"
                                >
                                  <label class="d-block" for="edo-ani13">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani13"
                                      type="radio"
                                      name="rdo-ani6"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block" for="edo-ani14">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani14"
                                      type="radio"
                                      name="rdo-ani6"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-3 col-sm-4">
                                <label class="mb-0 sm-label-radio"
                                  >Apply discount</label
                                >
                              </div>
                              <div class="col-xl-9 col-sm-8">
                                <div
                                  class="form-group m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0"
                                >
                                  <label class="d-block mb-0" for="edo-ani15">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani15"
                                      type="radio"
                                      name="rdo-ani7"
                                    />
                                    Allow
                                  </label>
                                  <label class="d-block mb-0" for="edo-ani16">
                                    <input
                                      class="radio_animated"
                                      id="edo-ani16"
                                      type="radio"
                                      name="rdo-ani7"
                                      checked=""
                                    />
                                    Deny
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-tab> -->
                  </div>
                </b-tabs>
                <div class="text-right">
                  <button type="button" @click="submitForm" class="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { ValidationProvider } from 'vee-validate';
  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        address: '',
        phone_number: '',
        bio: '',
        level: '',
        logo: '',
        background:'',
        levelsData: [
          { id: 1, value: '$' },
          { id: 2, value: '$$' },
          { id: 3, value: '$$$' },
          { id: 4, value: '$$$$' },
          { id: 5, value: '$$$$$' }
        ]
      }
    },
    methods: {
      onFileSelected(e) {
        console.log(e);
        this.logo = e.target.files[0];
      },
      onBgSelected(e) {
        this.background = e.target.files[0];
      },
      submitForm() {
        this.$refs.form.validate()
          .then(async (isValid) => {
            // isValid: bool.
            if (isValid) {
              const vendorData = {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                name: this.name,
                phone_number: this.phone_number,
                address: this.address,
                level: this.level,
                bio: this.bio,
                ...(this.logo && { file: this.logo }),
                ...(this.background && { file2: this.background })
              };
              const result = await this.$store.dispatch(
                'vendor/createVendor',
                vendorData
              )
              if (result.status === 200) this.$router.push('/vendors/vendor-list')
            }
          })
      }
    }
  }
</script>
<style scoped></style>
