<template>
  <b-modal v-model="showModal" id="modal-1" size="lg" :title="title" @ok="onEdit($event)" @cancel="onCancel($event)"
    ok-title="Save" ok-variant="primary">
    <ValidationObserver ref="form">
      <form class="needs-validation">
        <div class="form row">
          <div class="col-md-6">
            <ValidationProvider v-if="selectedItem.name" rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* Vendor Name:</label>
                <input class="form-control" id="validationCustom01" type="text" name="Vendor Name"
                  v-model="userData.name" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* Phone Number:</label>
                <input class="form-control" id="validationCustom01" type="text" pattern="[0-9]{4,11}"
                  name="Phone Number" v-model="userData.phone_number" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* First Name:</label>
                <input class="form-control" id="validationCustom01" type="text" name="First Name"
                  v-model="userData.first_name" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* Last Name:</label>
                <input class="form-control" id="validationCustom01" type="text" name="Last Name"
                  v-model="userData.last_name" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider rules="required|email" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* Email:</label>
                <input class="form-control" id="validationCustom01" type="text" name="Email" v-model="userData.email" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-6" v-if="type === 'vendor'">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* Level:</label>
                <select class="custom-select" required="" v-model="userData.level">
                  <option value="">--Select--</option>
                  <option v-for="option in levelsData" :key="option.id" :value="option.id">
                    {{ option.value }}
                  </option>
                </select>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-8" v-if="type === 'vendor'">
            <ValidationProvider>
              <div class="form-group">
                <label for="logo1">Logo</label>
                <input type="file" class="form-control" name="logo" id="logo1" @change="onLogoSelected">
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-4" v-if="userData?.logo && userData.logo !== 'image' && userData.logo !== null">
            <figure class="d-flex flex-column">
              <span class="text-muted">current logo</span>
              <img :src="userData?.logo" width="50" class="img-fluid" alt="">
            </figure>
          </div>
          <div class="col-md-8" v-if="type === 'vendor'">
            <ValidationProvider>
              <div class="form-group">
                <label for="logo1">Background</label>
                <input type="file" class="form-control" name="background" id="logo1" @change="onBGSelected">
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-4" v-if="userData?.logo && userData.logo !== 'image' && userData.logo !== null">
            <figure class="d-flex flex-column">
              <span class="text-muted">current background</span>
              <img :src="userData?.background" width="100" class="img-fluid" alt="">
            </figure>
          </div>
          <div class="col-md-12" v-if="type === 'vendor'">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="validationCustom01" class="mb-1">* Address:</label>
                <textarea class="form-control" id="validationCustom01" type="text" name="Address"
                  v-model="userData.address" />
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-12" v-if="type === 'vendor'">
            <ValidationProvider>
              <div class="form-group">
                <label for="bio2" class="mb-1">Bio:</label>
                <textarea class="form-control" id="bio2" type="text" name="bio" v-model="userData.bio" />
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
  export default {
    name: 'GlobalUserModal',
    props: ['type', 'showModal', 'title', 'selectedItem'],
    data() {
      return {
        logo: '',
        background: '',
        userData: {},
        levelsData: [
          { id: 1, value: '$' },
          { id: 2, value: '$$' },
          { id: 3, value: '$$$' },
          { id: 4, value: '$$$$' },
          { id: 5, value: '$$$$$' }
        ]
      }
    },
    watch: {
      selectedItem(newValue) {
        if (newValue?.userInfo) {
          const { userInfo, ...rest } = newValue
          const updatedData = {
            ...rest,
            ...userInfo,
          }
          this.userData = { ...updatedData }
        } else {
          this.userData = {
            ...newValue
          }
        }
      }
    },
    methods: {
      onLogoSelected(e) {
        this.logo = e.target.files[0]
      },
      onBGSelected(e) {
        this.background = e.target.files[0];
      },
      resetUserData() {
        this.logo = '';
        this.background = '';
        if (this.selectedItem?.userInfo) {
          const { userInfo, ...rest } = this.selectedItem
          const updatedData = {
            ...rest,
            ...userInfo
          }
          this.userData = { ...updatedData }
        } else {
          this.userData = { ...this.selectedItem }
        }
      },
      onCancel(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.resetUserData();
        this.$emit('onCancel');
      },
      onEdit(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.form.validate().then((isValid) => {
          if (isValid) {

            // since userData include a nulled value
            // we're going to attache the file into a new object
            let vendorEditData = { ...this.userData };

            if (this.userData.logo === null || this.userData.logo === '') {
              vendorEditData = {
                ...this.userData,
                ...(this.logo && {
                  updated_logo: this.logo
                }),
              }
            }

            if (this.userData.background === null
              || this.userData.background === '') {
              vendorEditData = {
                ...this.userData,
                ...(this.background && {
                  updated_bg: this.background
                })
              }
            }

            console.log(vendorEditData);

            this.$emit('onEdit', vendorEditData);
          }
        })
      },

    }
  }
</script>
