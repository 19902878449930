import { render, staticRenderFns } from "./create-vendor.vue?vue&type=template&id=1a8aae07&scoped=true"
import script from "./create-vendor.vue?vue&type=script&lang=js"
export * from "./create-vendor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a8aae07",
  null
  
)

export default component.exports